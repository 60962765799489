import './App.css';
// import Header from './components/Header.js'
// import Footer from './components/Footer.js'
// import Main from './components/Main.js'
import Comingsoon from './components/Comingsoon.js'

function App() {
  return (
    <div className="App">
      <Comingsoon />
    </div>
  );
}


export default App;
